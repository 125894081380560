<template>
  <div class="ml-5 mr-5">
    <div class="text-right my-2">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>

<div class="text-left">
    <v-container fluid>
        <v-row>
          <v-col cols="3">
          <form>
            <v-text-field
              v-model="query"
              label="Search By Publication Name"
              required
            ></v-text-field>
          </form>
          </v-col>
          <v-col cols="3" class="my-4">
        <v-btn small color="primary" @click="getPublication(1)">
              Search
        </v-btn>
        <v-btn small @click="resetSearch()">
              Reset
        </v-btn>
         </v-col>
         <v-col cols="3">
            <v-checkbox
              v-model="show_zero_product_count"
              label="Publications without products"
              color="primary"
              value="yes"
              hide-details
              @click="getPublication(1)"
            ></v-checkbox>
          </v-col>
         <v-col cols="3" class="my-4 text-right">
            <template>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
              Add
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Publication</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit="addPublicationData"
              method="post"
              v-model="valid"
              lazy-validation
            >
              <v-container class="px-50" fluid>
                <v-text-field
                  v-model="addPublication.publicationName"
                  label="Publication Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <div style="color: red">
                  {{ message1 }}
                </div>
                <v-select
                  v-model="addPublication.print_type"
                  :items="print_types"
                  :menu-props="{ maxHeight: '400' }"
                  label="Select Publication Print Type"
                ></v-select>
                <v-select
                  :items="audience"
                  label="Select Publication Audience"
                  v-model="addPublication.audience"
                ></v-select>
                <v-select
                  :items="state"
                  label="Select Publication State/Province"
                  v-model="addPublication.state"
                ></v-select>
                <v-select
                  :items="country"
                  label="Select Publication Primary Country"
                  v-model="addPublication.country"
                ></v-select>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate">
                  Submit
                </v-btn>
                <v-btn class="close_button mx-2" text @click="reset"> Close </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>

      <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="error" v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('delete')">Delete</v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
          <template>
            <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                  Are you sure you want to delete all the selected Record(s)?
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn
                    text
                    class="confirm_button mx-2"
                    v-on:click="dialog2 = false"
                    @click="deletePublication"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
        <div v-if="deleteItems.length == 0">
          <template>
            <v-card>
              <v-card-text class="center">
                <div class="text-h3 pa-12">
                  Please Select Record(s) to delete
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
      </v-dialog>
         </v-col>
       </v-row>
     </v-container>
</div>

    </div>



 <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox" v-show="checkIfOperationExistForModule('delete')">
              <v-checkbox
                ref="n"
                color="default"
                v-model="allSelected"
                @click="selectAll"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :page.sync="page"
              :items="publication_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item.selectCheckbox="{ item }">
                <v-checkbox
                  ref="n"
                  v-if="item.product_count === 0"
                  v-model="deleteItems"
                  :value="item.publication_id"
                   :key="item.publication_id"
                   v-show="checkIfOperationExistForModule('delete')"
                  @click="addToDelete($event, item.publication_id)"
                ></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                      medium
                      color="grey"
                      @click="addToUpdate($event, item.publication_id)"
                      v-show="checkIfOperationExistForModule('edit')"
                    >
                      mdi-pencil
                    </v-icon>
              </template>
            </v-data-table>
            <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>

            <div></div>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
     <v-dialog
      v-model="toggleUpdateModal"
      max-width="600"
    >
      <template>
    <v-card>
                    <v-card-title>
                      <span class="headline">Update Publication</span>
                    </v-card-title>
                    <v-form
                      ref="form"
                      @submit="updatePublicationData"
                      method="put"
                    >
                      <v-container class="px-50" fluid>
                        <v-text-field
                          v-model="updatePublication.publicationName"
                          label="Publication Name"
                          :rules="nameRules"
                          required
                        ></v-text-field>
                        <div style="color: red">
                          {{ upmessage1 }}
                        </div>
                      </v-container>
                      <v-container class="px-50" fluid>
                        <v-select
                          v-model="updatePublication.print_type"
                          :items="print_types"
                          :menu-props="{ maxHeight: '400' }"
                          label="Select Publication Print Type"
                        ></v-select>
                        <v-select
                          :items="audience"
                          label="Select Publication Audience"
                          v-model="updatePublication.audience"
                        ></v-select>
                        <v-select
                          :items="state"
                          label="Select Publication State/Province"
                          v-model="updatePublication.state"
                        ></v-select>
                        <v-select
                          :items="country"
                          label="Select Publication Primary Country"
                          v-model="updatePublication.country"
                        ></v-select>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          @click="updatePublicationData"
                          type="submit"
                        >
                          Update
                        </v-btn>
                        <v-btn class="close_button mx-2" text @click="toggleUpdateModal = false">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
	  </template>
     </v-dialog>
</v-row>
        <!-- v-data-table-ends -->
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import Pagination from "./component/Pagination.vue";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading, Pagination },
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      show_zero_product_count:'no',
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "10%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Publication Name",
          value: "publication_name",
          width: "30%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Print Type Name",
          value: "publication_printType",
          width: "30%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Count",
          value: "product_count",
          width: "15%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Action",
          value: "actions",
          width: "15%",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      ///
      valid: true,
      checkbox: false,
      fullPage: true,
      publication: [],
      checkbox1: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      print_types: [],
      audience: [],
      state: [],
      country: [],
      query: "",
      category: "",
      deleteItems: [],
      checked_publicationid: [],
      checkcedstr: "",
      product_data: [],
      productCount: [],
      publicationIds: [],
      publication_data: [],
      pageNo: 0,
      message1: null,
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      publicationNames: [],
      addPublication: {
        publicationName: null,
        print_type: null,
        audience: null,
        state: null,
        country: null,
      },
      updatePublication: {
        publicationId: null,
        publicationName: null,
        print_type: null,
        audience: null,
        state: null,
        country: null,
      },
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      showPerPage: false,
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
    //pagination methods
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getPublication(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getPublication(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getPublication(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getPublication(this.page);
    },
    /////
    resetSearch(){
      this.page = 1,
      this.query = "",
      this.show_zero_product_count = "no",
      this.getPublication();
     },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = null;
      this.upmessage1 = "";
      // this.$refs.form.resetValidation();
      // this.$refs.form.reset();
    },
    getPublication(page = "") {
      let page_no = page == '' ? this.page : page
      let check = this;
      //check.$url("PUBLICATION_SEARCH")
      //const path = 'http://0.0.0.0:5011/publications-search'
      const path = check.$url("PUBLICATION_SEARCH");
      check.isLoading = true;
      let searchData ={
        "q": check.query.trim(),
        "page": page_no,
        "show_zero_product_count": this.show_zero_product_count
      }
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        .post(path, searchData)
        .then((res) => {
          check.isLoading = false;
          console.log(res.data.payload.publicationId);
          let result = res.data.payload.publicationId
          check.publication = result[0];
          if(check.totRecords != result[6]){
            check.page = 1
          }
          check.totRecords = result[6];
          check.total = this.publication.length;
          if (result[2].length) {
            result[2].forEach(function (item) {
              let a = {
                text: item[1],
                value: item[0],
              };
              check.print_types.push(a);
            });
          }
          if (result[3].length) {
            result[3].forEach(function (item) {
              let a = {
                text: item[1],
                value: item[0],
              };
              check.audience.push(a);
            });
          }
          if (result[4].length) {
            result[4].forEach(function (item) {
              let a = {
                text: item[1],
                value: item[0],
              };
              check.state.push(a);
            });
          }
          if (result[5].length) {
            result[5].forEach(function (item) {
              let a = {
                text: item[1],
                value: item[0],
              };
              check.country.push(a);
            });
          }
          this.pageCount = result[1];
          if(this.totRecords != result[6]){
            this.page = 1
          }
          let publication = this.publication;
          if (Array.isArray(publication) && publication.length) {
            this.publication_data = [];
            let i;
            for (i = 0; i < publication.length; i++) {
              let tempobj = {
                publication_id: publication[i][0],
                publication_name: publication[i][1],
                publication_printType: publication[i][2],
                product_count: publication[i][7] == null ? 0 : publication[i][7],
              };
              this.publication_data.push(tempobj);
              this.publicationNames.push(publication[i][1]);
            }
          }

          // if (this.publication == 0) {
          //   this.publication_data = [];
          //   this.publicationIds = [];
          // } else {
          //   let temp = this;
          //   temp.publicationIds = [];
          //   temp.publication.forEach(function (o, i) {
          //     temp.publicationIds.push(o[0]);
          //   });

          //   this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
          //     .get(check.$url("PUBLICATIONID"), {
          //       params: { ids: temp.publicationIds },
          //     })
          //     .then((res) => {
          //       let productCount = res.data.payload.count;
          //       let i;
          //       for (i = 0; i < productCount.length; i++) {
          //         this.publication_data[i].product_count = productCount[i][1];
          //         if (this.publication_data[i].product_count != 0) {
          //           this.publication_data[i].publication_name =
          //             this.publication_data[i].publication_name + "*";
          //         }
          //       }
          //     });
          // }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addPublicationData(e) {
      e.preventDefault();
      let check = this;
      if (
        this.addPublication.publicationName.trim() != "" &&
        this.addPublication.publicationName != null
      ) {
        this.isLoading = true
         this.addPublication.publicationName = this.addPublication.publicationName.trim()
        //this.$url("PUBLICATION")
        //http://0.0.0.0:5011/publications
        this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: check })
          .post(this.$url("PUBLICATION"), this.addPublication)
          .then((result) => {
            check.isLoading = false
            console.log(result.data.payload[0]);
            this.message1 = result.data.payload[0].publicationId;
            this.message2 = result.data.payload[0].publicationName;
            console.log(result.data.payload[0].publicationId);
            if (result.data.payload[0].publicationName == undefined) {
              check.message1 = result.data.payload[0].publicationId;
              check.dialog = true;
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.getPublication();
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) added successfully",
                  text: result.data.payload.message,
                });
                this.addPublication =  {
                  publicationName: null,
                  print_type: null,
                  audience: null,
                  state: null,
                  country: null,
                }
              }
              this.reset();
            }
            console.log(result);
          }).catch((error) => {
           console.error(error);
           check.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }else{
        data.message1 = 'Please enter publication name!'
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      this.toggleUpdateModal = true
      view.publication.forEach(function (o, i) {
        if (o[0] == atchid) {
          view.updatePublication.publicationName = o[1];
          view.updatePublication.print_type = o[3];
          view.updatePublication.audience = o[4];
          view.updatePublication.state = o[5];
          view.updatePublication.country = o[6];
          view.updatePublication.publicationId = o[0];
        }
      });
    },
    updatePublicationData(e) {
      e.preventDefault();
      let data = this;
      if (
        data.updatePublication.publicationName.trim() != "" &&
        data.updatePublication.publicationName != null
      ) {
        this.isLoading = true
        this.updatePublication.publicationName = this.updatePublication.publicationName.trim()
        //this.$url("PUBLICATION")
        this.$fetch({ requiresAuth: true, operation : 'edit', vueScope: data })
          .put(this.$url("PUBLICATION"), data.updatePublication)
          .then((result) => {
            data.isLoading = false
            data.upmessage1 = result.data.payload.publicationId;
            console.log(result.data.payload.publicationId);
            if (result.data.payload.publicationName == undefined) {
              data.upmessage1 = result.data.payload.publicationId;
            }else{
              data.upmessage1 = ''
              if (result.data.statusCode == 200) {
              data.getPublication();
              data.$swal.fire({
                icon: "success",
                title: "Record(s) updated successfully",
                text: result.data.payload.message,
              });
              data.toggleUpdateModal = false
            }
            }
            console.log(result);
          }).catch((error) => {
           console.error(error);
           data.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }else{
        data.upmessage1 = 'Please enter publication name!'
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_publicationid.indexOf(e.target.value) == -1) {
          this.checked_publicationid.push(e.target.value);
        }
      } else {
        this.checked_publicationid.splice(
          this.checked_publicationid.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_publicationid.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deletePublication(e) {
      e.preventDefault();
      let check = this;
      this.isLoading = true
      console.log(this.deleteItems);
      this.$fetch({ requiresAuth: true , operation : 'delete', vueScope: check })
        .delete(this.$url("PUBLICATION"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          check.isLoading = false
          if (response.data.statusCode == 200) {
            this.getPublication();
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message,
            });
          }
          this.deleteItems = [];
        }).catch((error) => {
           console.error(error);
           check.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
    },
    selectAll: function () {
      let delpublication = this.publication;
      if (this.allSelected == true) {
        if (Array.isArray(delpublication) && delpublication.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < delpublication.length; i++) {
            if (this.publication_data[i].product_count == 0) {
              this.deleteItems.push(delpublication[i][0]);
            }
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
    searchPublication: function () {
      this.getPublication(this.page, this.query);
      console.log(this.query);
    },
  },
  watch: {
    '$store.state.Rawdata.current_active_module': function(o) {
      if(o){
      this.getPublication(1);
      }
    },
    page: function (ob) {
      this.allSelected = false;
      this.deleteItems = [];
      this.getPublication(ob);
    },
 },
};
</script>
